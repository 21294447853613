/*
const obj = {
  filter:{
    test1:1,
    test2:{test3:3,test4:4},
    test5:[6,7]
  }
}
queryStringGenerator(obj);
result: "filter[test1]=1&filter[test2][test3]=3&filter[test2][test4]=4&filter[test5][0]=6&filter[test5][1]=7"
*/

export const objectToQueryString = (obj) => {
    let getPairs = (obj, keys = []) =>
        Object.entries(obj).reduce((pairs, [key, value]) => {
            if (typeof value === "object") pairs.push(...getPairs(value, [...keys, key]));
            else pairs.push([[...keys, key], value]);
            return pairs;
        }, []);

    let string = getPairs(obj)
        .map(
            ([[key0, ...keysRest], value]) =>
                `${key0}${keysRest.map((a) => `[${encodeURIComponent(a)}]`).join("")}=${encodeURIComponent(value)}`,
        )
        .join("&");
    return string;
};

export const queryStringToObject = (str) => {
    const obj = {};

    const processValue = (key, value, obj) => {
        if (key.includes("[")) {
            const keys = key.split(/\[|\]/).filter(Boolean);
            let temp = obj;
            for (let i = 0; i < keys.length; i++) {
                const currentKey = keys[i];
                if (!temp[currentKey]) {
                    if (i === keys.length - 1) temp[currentKey] = value;
                    else temp[currentKey] = isNaN(keys[i + 1]) ? {} : [];
                }
                temp = temp[currentKey];
            }
        } else obj[key] = value;
    };

    str.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        processValue(decodedKey, decodedValue, obj);
    });

    return obj;
};
